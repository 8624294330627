
/* RESET */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
body, h1, h2, h3, h4, p,
figure, blockquote, dl, dd {
  margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  padding: 0;
  margin: 0;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4,
button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2,
h3, h4 {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button,
textarea, select {
  font-family: inherit;
  font-size: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}

/* MSA Specifics */
:root {
    --white: #ffffff;
    --black: #000000;
    --rich-black: #191919;
    --off-white: #F9F9F9;
    --blue-grey-900: #1E293B;
    --blue-grey-800: #334155;
    --blue-grey-700: #475569;
    --blue-grey-500: #7B8BA3;
    --blue-grey-300: #B5C3D4;
    --blue-grey-200: #D3DCE8;
    --blue-grey-100: #E2EBF3;
    --deep-blue-900: #1D315D;
    --msa-blue-600: #3949DC;
    --msa-blue-800: #242E9F;
    --secondary: #FBB700;
    --light-blue: #93ACD1;
    --light-blue-200: #C9D7EB;
}

.bg-blue-600 {
    background-color: var(--msa-blue-600);
}
.bg-light-blue {
    background-color: var(--light-blue);
}
.bg-light-blue-200 {
    background-color: var(--light-blue-200);
}
.bg-rich-black {
    background-color: var(--rich-black);
}
.bg-deep-blue {
    background-color: var(--deep-blue-900);
}
.color-blue-600 {
    color: var(--msa-blue-600);
}

body {
    font-family: "Anek Latin", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--rich-black);
}

.container {
    max-width: 1124px;
    margin: 0 auto;
}

@media(min-width:1024px) {
    .grid-3-9 {
        display: grid;
        grid-template-columns: 3fr 9fr;
    }
    .grid-4-7 {
        display: grid;
        grid-template-columns: 4fr 7fr;
    }
    .grid-4-8 {
        display: grid;
        grid-template-columns: 4fr 8fr;
    }
    .grid-1-1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.gap-16 {
    gap: 16px;
}

.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}

.fs-22 {
    font-size: 22px;
}
.fs-24 {
    font-size: 24px;
}
.fs-32 {
    /* font-size: 32px; */
    font-size: clamp(1.875rem, 1.831rem + 0.1878vw, 2rem);
}
.fs-36 {
    font-size: 36px;
}

.fs-40 {
    /* font-size: 40px; */
    font-size: clamp(2rem, 1.8239rem + 0.7512vw, 2.5rem);
}
.fs-60 {
    /* font-size: 60px; */
    font-size: clamp(2.5rem, 2.0599rem + 1.8779vw, 3.75rem);
    
}
.fs-66 {
    /* font-size: 66px; */
    font-size: clamp(3.75rem, 3.618rem + 0.5634vw, 4.125rem);
}

.fw-300 {
    font-weight: 300;
}

.fw-300 strong, p.fw-300 strong {
    font-weight: 500;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.lh-120 {
    line-height: 120%;
}

.lh-130 {
    line-height: 130%;
}

.m-0 {
    margin: 0;
}

.mb-24 {
    margin-bottom: 24px;
}
.mb-6 {
    margin-bottom: 6px;
}

.t-align-center {
    text-align: center;
}

.tt-u {
    text-transform: uppercase;
}

.display-block {
    display: block;
}

.w-100 {
    width: 100%;
}

input, textarea {
    border: 1px solid var(--blue-grey-300);
    border-radius: 8px;
    background-color: var(--white);
    padding: 10px 14px;
}

input:focus, textarea:focus {
    border-color: var(--msa-blue-600);
}

::placeholder {
    color: var(--blue-grey-500);
    opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
color: var(--blue-grey-500);
}

.has-error input, .has-error textarea {
    border: 1px solid #C71C22;
}

.has-error input {
    background-image: url('assets/img/alert-circle.svg');
    background-repeat: no-repeat;
    background-position: 98% 12px;
}

.has-error span {
    color: #C71C22;
    font-size: 14px;
    display: block;
}

.has-error label span {
    display: inline;
    color: var(--msa-blue-600);
    font-weight: 500;
}

/* GLOBAL */

/* buttons */
.text-btn {
  text-decoration: none;
  color: var(--rich-black);
  font-size: 18px;
  font-weight: 400;
  transition: color ease .3s;
}
.text-btn strong {
  font-weight: 500;
}
.text-btn:hover {
  color: var(--msa-blue-600);
}

.btn {
  font-size: 18px;
  padding: 4px 16px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
  transition: all ease .3s;
  border-style: none;
  border: none;
  text-align: center;
  cursor: pointer;
}

.btn-primary {
  color: var(--white);
  background-color: var(--msa-blue-600);
}
.btn-primary:hover {
  color: var(--white);
  background-color: var(--msa-blue-800);
}

.btn-secondary {
  color: var(--deep-blue-900);
  background-color: var(--secondary);
}

.btn-secondary:hover {
  color: var(--white);
  background-color: var(--msa-blue-600);
}

.btn-large {
  font-size: 24px;
  padding: 8px 24px;
  font-size: 24px;
}

button.btn {
  line-height: 28px;
  cursor: pointer;
}
           
/* PREHEADER */
.preHeader {
  color: var(--blue-grey-300);
  font-size: 14px;
  background-color: var(--deep-blue-900);
  display: flex;
  justify-content: end;
  gap: 32px;
  align-items: center;
  padding: 4px;
}

@media (min-width:992px) {
  .preHeader {
      padding: 12px 64px;
  }
}

.preHeader a {
  color: var(--blue-grey-300);
  transition: color ease .3s;
  text-decoration: none;
}

.preHeader a:hover {
  color: var(--blue-grey-100);
}

.preHeader p {
  margin: 0;
}

.preHeader-contact-info {
  display: none;
}

@media (min-width:992px) {
  .preHeader-contact-info {
      display: flex;
      gap: 20px;
  }
  
}

.preHeader-linkedin-link {
  transition: opacity ease .3s;
  display: none;
}

.preHeader-linkedin-link:hover {
  opacity: .75;
}

@media (min-width:992px) {
  .preHeader-linkedin-link {
      display: block;
  }
}

/* HEADER */
.siteHeader {
  background-color: var(--off-white);
  padding: 24px;
}

@media(min-width:992px) {
  .siteHeader {
      padding: 24px 64px;
  }
}

.mobileMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuToggler {
  display: flex;
  flex-direction: column;
  height: 14px;
  justify-content: space-between;
  cursor: pointer;
}

.menuToggler .line {
  height: 2px;
  width: 18px;
  border-radius: 18px;
  background-color: var(--deep-blue-900);
  transition: width ease .3s;
}

.menuToggler:hover .line:nth-child(2) {
  width: 12px;
}

.mainNav { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 81.6vw;
  max-width: 306px;
  height: 100vh;
  position: fixed;
  left: -100vw;
  background-color: var(--off-white);
  top: 0;
  z-index: 1;
  transition: left ease .5s;
}

.mainNav.open {
  left: 0;
}

@media (min-width:1024px) {
  .mobileMenu {
      display: none;
  }
  .mainNav {
      flex-direction: row;
      width: 100%;
      max-width: none;
      height: auto;
      position: static;
      left: auto;
      top: auto;
  }
}

.brand {
  position: relative;
}

.brand button {
  position: absolute;
  background: none;
  border: none;
  border-style: none;
  right: 0;
  top: 12px;
}

@media (max-width:1023px) {
  .mainNav {
      padding: 4px 15px 44px;
  }
  .brand {
      border-top: 1px solid var(--blue-grey-200);
      border-bottom: 1px solid var(--blue-grey-200);
      padding: 20px 0;
      width: 100%;
  }
  .brand a img {
      margin: 0 auto;
  }
}

@media (min-width:1024px) {
  .brand button {
      display: none;
  }
}

.mobile-nav-overlay {
  background-color: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity ease .3s;
}

.mobile-nav-overlay.show {
  opacity: 1;
  z-index: 0;
}

/* projects nav */
.projectsNav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 24px;
  align-items: center;
  flex-direction: column;
}

@media (min-width:1024px) {
  .projectsNav {
      flex-direction: row;
  }
}

/* more links */
.moreLinks {
  display: flex;
  gap: 24px;
  align-items: center;
  flex-direction: column;
  padding-top: 24px;
  border-top: 1px solid var(--blue-grey-200);
}

.moreLinks .preHeader-linkedin-link {
  display: block;
}

@media (max-width:1023px) {
  .moreLinks {
      width: 100%;
  }

  .moreLinks .btn {
      width: 100%;
  }

  .moreLinks .text-btn::after {
      content: '';
      background-image: url('assets/img/arrow-up-right.svg');
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      position: relative;
      top: 6px;
  }
}

@media (min-width:1024px) {
  .moreLinks {
      flex-direction: row;
      gap: 18px;
      padding-top: 0;
      border-top: none;
  }
  .moreLinks .preHeader-linkedin-link {
      display: none;
  }
}

/* language selector */
.language-selector {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 4px;
  list-style: none;
}

.language-selector li:first-child {
  border-right: 1px solid var(--blue-grey-100);
  padding-right: 4px;
}

.language-btn {
  padding: 8px 12px;
  border-radius: 8px;
  text-transform: uppercase;
  color: var(--blue-grey-500) ;
  background: none;
  border-style: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all ease .3s;
}

.language-btn.active, .language-btn:hover {
  border: 1px solid var(--blue-grey-200);
}

.language-btn.active {
  cursor: default;
}

/* HOME HERO */
.home-hero {
  background-color: var(--deep-blue-900);
}

@media(min-width:1024px){
  .home-hero {
      display: grid;
      grid-template-columns: 2.2fr 3fr;
      grid-column-gap: 0;
      align-items: center;
      display: none;
  }
}

.home-hero-text {
  padding: 48px 24px;
}

.home-hero-text h1 {
  color: var(--white);
  line-height: 1;
  margin: 64px 0 43px 0;
  padding-top: 64px;
  border-top: 1px solid var(--light-blue);
  max-width: 389px;
}

.home-hero-text p {
  font-size: clamp(1.125rem, 1.037rem + 0.3756vw, 1.375rem);
  font-weight: 300;
  margin: 0 0 64px 0;
  color: var(--white);
  max-width: 389px;
}

.home-hero-video {
  display: none;
}

.home-hero-logos {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  row-gap: 12px;
  padding: 12px 14px 24px;
  justify-content: space-between;
}
.home-hero-logos a {
  display: block;
  padding: 0 16px;
  height: 44px;
}
.home-hero-logos a img {
  display: block;
  margin: 0 auto;
}

@media(min-width:1024px){
  .home-hero-text {
      padding: 0 64px;
  }
  .home-hero-video {
      display: block;
  }
  .home-hero-logos {
      display: none;
  }
}

.home-hero-desktop {
  position: relative;
  display: none;
}
.home-hero-desktop h1 {
  position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
    color: #fff;
    text-shadow: 0px 1px 2px rgba(16, 24, 40, .1);
    margin: 0;
    padding: 0 24px;
}
.home-hero-desktop video {
  width: 100%;
  height: auto;
}

@media(min-width:1024px) {
  .home-hero-desktop {
    display: block;
  }
}

/* QUIENES SOMOS */
.who {
  padding: 128px 24px;
  background-color: #F9F9F9;
}

@media (max-width:1023px) {
  .who h2 {
    margin-bottom: 48px;
  }
}

@media(min-width:1024px) {
  .who {
    padding: 256px 0;
  }
}

/* PROJECTS */
.projects {
  display: none;
}
.projects-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0;
}
.projects-mobile .project-col.bg-blue-600,
.projects-mobile .project-col.bg-rich-black, 
.projects-mobile .project-col.bg-light-blue {
  height: 100%;
}

.project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0;
  text-decoration: none;
  color: var(--white);
}
.project-col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media(min-width:1024px) {
  .projects-mobile {
      display: none;
  }
  .projects {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0;
  }
}

@media(min-width:1280px) {
  .projects {
      grid-template-columns: 1fr 1fr 1fr;
  }
}

.project-logo {
  width: 100%;
}
.project-logo-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(29, 49, 93, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color ease .3s;
}
.project-desc {
  opacity: 0;
  transition: opacity ease .3s;
  padding:  16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-desc h2 {
  margin: 0 0 12px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}
.project-desc p {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 300;
}
.project-deco {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity ease .3s;
}
.project:hover .project-desc {
  opacity: 1;
}
.project:hover .project-deco {
  opacity: 0;
}
.project:first-child:hover .project-col:first-child .project-logo-overlay {
  background-color: var(--msa-blue-600);
}
.project:nth-child(2):hover .project-col:last-child .project-logo-overlay {
  background-color: var(--light-blue);
}
.project:nth-child(3):hover .project-col:last-child .project-logo-overlay {
  background-color: var(--msa-blue-600);
}
.project:nth-child(4):hover .project-col:first-child .project-logo-overlay {
  background-color: var(--rich-black);
}
.project:nth-child(5):hover .project-col:first-child .project-logo-overlay {
  background-color: var(--deep-blue-900);
}
.project:last-child:hover .project-col:first-child .project-logo-overlay {
  background-color: var(--rich-black);
}

/* DIAGRAMA */
.diagram {
  padding: 72px 24px;
  background-color: #C9D7EB;
}
.diagram img {
  width: 100%;
  max-width: 1080px;
}
.diagram .diagram-desk {
  display: none;
}
@media (min-width:768px) {
  .diagram {
      padding: 120px 180px;
  }
  .diagram .diagram-desk {
      display: block;
  }  
  .diagram .diagram-mob {
      display: none;
  }  
}

/* SOÑAMOS EN GRANDE */
.dream {
  padding: 64px 24px;
  color: var(--white);
}

.dream .grid-4-7 {
  gap: 112px;
}

.dream h2 {
  margin: 0 0 36px 0;
  padding-bottom: 36px;
  border-bottom: 1px solid var(--white);
}

.dream .dream-title {
  margin-bottom: 48px;
}

.dream .dream-title p {
  max-width: 203px;
}

.dream-copy p {
  margin: 0;
}

@media(min-width:1024px){
  .dream {
    padding: 112px 0;
  }
  .dream h2 {
    margin: 0 0 76px 0;
    padding-bottom: 76px;
  }
  .dream .dream-title {
    margin-bottom: 0;
  }
}

/* NEWS */
.news {
  background-color: #F9F9F9;
  padding: 64px 24px;
}
@media(min-width:1024px) {
  .news {
    padding: 96px 0;
  }
}
.news-feed-header {
  position: relative;
  margin-bottom: 64px;
}

.news-feed-header .btn {
  margin-top: 32px;
}

@media(min-width:1024px) {
  .news-feed-header .btn {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

.news-feed-header h3 {
  margin: 0 0 12px 0;
  color: var(--msa-blue-600);
}
.news-feed-header h2 {
  color: var(--blue-grey-900);
  margin: 0 0 20px 0;
}

@media(min-width:1024px) {
  .news-feed {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
  }
}

@media(max-width:1023px) {
  .news-feed article {
    margin-bottom: 32px;
  }
}

.news-feed article a {
  text-decoration: none;
  color: var(--blue-grey-900);
  transition: opacity ease .3s;
}
.news-feed article a:hover {
  opacity: .8;
}
.news-img {
  position: relative;
  margin-bottom: 20px;
}
.news-img::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 68px;
  height: 68px;
  background-image: url('assets/img/corner.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.news-img img {
  border-radius: 8px;
}
.news-date {
  margin: 0 0 8px 0;
  font-size: 14px;
  line-height: 120%;
  font-weight: 400;
}
.news-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  margin: 0 0 8px 0;
  padding-right: 40px;
  position: relative;
  font-weight: 600;
  line-height: 130%;
  font-size: 24px;
}
.news-title::after {
  content: '';
  position: absolute;
  height: 28px;
  width: 24px;
  background-image: url('assets/img/external-link-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  right: 0;
  top: 0;
}
.news-lead {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0 0 8px 0;
  font-size: 16px;
  line-height: 130%;
  font-weight: 400;
  color: var(--blue-grey-700);
}
.read-more {
  color: var(--msa-blue-600);
  font-size: 14px;
  line-height: 120%;
  text-decoration: underline;
}

/* WHAT */
.what {
  padding: 144px 24px;
  color: var(--white);
}

.what img {
  margin: 0 auto 30px;
}

@media(max-width:1023px) {
  .what .fs-60 {
    font-size: 30px !important;
  }
}

@media(min-width:1024px) {
  .what {
    padding: 202px 0;
  }
}

/* QUALITY */
.quality {
  max-width: 1284px;
  margin: 0 0 0 auto;
}

@media (min-width: 768px) {
  .quality {
      display: grid;
      grid-template-columns: 1fr max-content;
      justify-content: space-between;
  }
}

.quality-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;
  margin-bottom: 36px;
}

@media(min-width:1024px) {
  .quality-text {
    max-width: 380px;
    padding: 0;
    gap: 36px;
    margin-bottom: 0;
  }
}

.quality h2 {
  padding-bottom: 48px;
  border-bottom: 1px solid var(--rich-black);
}

.quality .tt-u {
  margin: 8px 0 0 0;
}

.partners {
  list-style: none;
  padding: 0;
  margin: 8px 0 40px 0;
  display: flex;
  gap: 24px;
  align-items: center;
}

.quality-text p.fs-22.fw-300.lh-130 {
  margin: 0;
}

.square-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 648px;
}

.square-grid div {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media(min-width:768px) {
  .square-grid div {
      aspect-ratio: 1 / 1;
      padding: 32px;
  }
}

.square-grid h3, .square-grid p {
  width: 100%;
}

.square-grid h3 {
  margin: 0 0 8px 0;
}

@media(min-width:1024px) {
  .square-grid h3 {
    margin: 0 0 24px 0;
  }
}

.square-grid p {
  margin: 0;
}

.square-grid .bg-light-blue-200 {
  color: var(--deep-blue-900);
}
.square-grid .bg-blue-600 {
  color: var(--white);
}

/* WELCOME */
.welcome {
  display: flex;
  flex-direction: column;
}

@media(min-width:1024px) {
  .welcome {
      display: grid;
      grid-template-columns: 1fr 1fr;
  }    
}

.welcome-col {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
  padding: 32px 24px 64px;
}

@media(min-width:1024px) {
  .welcome-col {
    order: initial;
    padding: 0;
  }
}

.welcome-text {
  max-width: 408px;
}

.welcome-text h2 {
  padding-top: 48px;
  border-top: 1px solid var(--deep-blue-900);
  color: var(--rich-black);
  margin: 48px 0;
}

.welcome-text p {
  margin: 0 0 48px 0;
  color: var(--deep-blue-900);
}

.welcome-img-desk {
  display: none;
}

@media(min-width:1024px) {
  .welcome-img-desk {
    display: block;
  }
  .welcome-img-mob {
    display: none;
  }
}

/* MAP */
.map {
  background-color: var(--deep-blue-900);
}

@media(min-width:1024px) {
  .map {
    padding: 45px 0;
  }
}

.map-container {
  display: none;
  position: relative;
}

@media(min-width:1024px) {
  .map-container {
    display: block;
  }
}

.map-container img {
  display: block;
  margin: 0 auto;
}

.locations {
  position: absolute;
  right: 7.91%;
  top: 50%;
  transform: translate(0, -50%);
}

.locations p {
  font-size: 22px;
  line-height: 130%;
  font-weight: 300;
  color: var(--white);
  margin: 0 0 32px 0;
}

.locations p:last-child {
  margin-bottom: 0;
}

.map-mobile img {
  width: 100%;
  display: block;
}

@media(min-width:1024px) {
  .map-mobile {
    display: none;
  }
}

/* CONTACT */
.contact {
  background-color: var(--off-white);
  padding: 64px 24px;
}
.contact h2 {
  margin-bottom: 32px;
}
form small {
  font-size: 14px;
  color: var(--blue-grey-800);
  line-height: 120%;
}
@media(min-width:1024px) {
  .contact { padding: 64px; }
}

.prensa-contact {
  margin-bottom: 32px;
}

.prensa-contact p, .prensa-contact a {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 0 8px 0;
  color: var(--deep-blue-900);
}

.prensa-contact a {
  text-decoration: none;
}

/* FOOTER */
.siteFooter {
  background-color: var(--rich-black);
  padding: 48px 64px;
  display: flex;
  justify-content: space-between;
  color: #f9f9f9;
  align-items: start;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

@media (min-width:1024px) {
  .siteFooter {
      flex-direction: row;
      align-items: start;
  }
}

.siteFooter div {
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 300;
}

.siteFooter div:last-child {
  display: block;
  color: var(--light-blue);
  text-align: center;
}

@media (min-width:1024px) {
  .siteFooter div:last-child {
      text-align: right;
  }
}

.siteFooter div:last-child a {
  color: var(--blue-grey-300);
  text-decoration: underline;
  transition: color ease .3s;
}

.siteFooter div:last-child a:hover {
  color: var(--blue-grey-100);
}

.siteFooter a {
  color: #f9f9f9;
  transition: color ease .3s;
  text-decoration: none;
}

.siteFooter a:hover {
  color: #B5C3D4;
}

.siteFooter p {
  margin: 0;
}